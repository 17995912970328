
.screen-container {
  width: auto;
  max-width: 100%;
  background-color: black;
  border-radius: 8px;
  border: 8px solid #333;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f4f4c2;
  background-color: #1d1d1d;
  width: auto;
  max-width: 100%;
  height: auto;
  min-width: 300px;
  min-height: 200px;
  overflow: hidden;
}

.img {
  max-width: 100%;
  height: auto;
  min-width: 300px;
  min-height: 200px;
  object-fit: contain;
}
