.device-card {
  height: 220px;
  background-color: #3a3a3a;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.device-card:hover {
  background-color: #4b4b4b;
}

.status-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
}

.device-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 141px;
  width: 100%;
  overflow: hidden;
}

.device-footer {
  color: white;
  font-size: 14px;
}

.device-id {
  margin-left: 5px;
}

.icon-config:hover {
  transform: scale(1.3);
}

.device-image {
  object-fit: contain;
  margin-top: 20px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}