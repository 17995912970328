.div-register{
    width: 90%;
    max-width: 400px;
    padding: 1rem;
    border-radius: 6px;
    border: solid 1px silver;
    background: white;
  }
  
  .msg-error{
    margin-top: 10px;
    color: red;
  }
  