/* Home.css */
.home-dark-theme {
  background-color: #1a1a1d;
  color: #f4f4f4;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  max-width: 98%;
  padding: 0;
}

.home-row {
  padding: 2rem;
}

.home-card {
  background-color: #2c2c34;
  border: none;
  border-radius: 10px;
}

.home-title {
  font-size: 3rem;
  color: #ffffff;
}

.title-connect-devices {
  font-size: 30px;
}

.home-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #cccccc;
}

.home-card button {
  background-color: #28a745;
  border: none;
}

.home-card button:hover {
  background-color: #218838;
}

.small{
  font-size: 13px;
}